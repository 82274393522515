var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "z-page",
    { staticClass: "container" },
    [
      !!_vm.token
        ? _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.doLogout } },
            [_vm._v("退出登录")]
          )
        : _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.goToLogin } },
            [_vm._v("立 即 登 录")]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }