
import {
	getLoginConfig,
	getToken,
	getUserInfo,
	goToAccountCenter,
	loginInit,
	loginWithIDaaSCode,
	logout
} from '@/utils/auth'
import { Loading } from 'element-ui'
import { Component, Vue } from 'vue-property-decorator'

@Component({
	data() {
		return {}
	}
})
export default class Login extends Vue {
	private token = ''

	activated() {
		this.token = getToken()
	}
	created() {
		this.token = getToken()
		this.loginWithCode()
	}

	toAccountCenter() {
		goToAccountCenter()
	}

	async loginWithCode() {
		const { query } = this.$route
		const { code }: any = query
		if (code && !this.token) {
			const loadingInstance1 = Loading.service({ fullscreen: true })
			const isLogin = await loginWithIDaaSCode(code).finally(() => {
				setTimeout(() => loadingInstance1.close(), 1000)
			})
			if (isLogin) {
				this.token = getToken()
				await getUserInfo()
				this.$router
					.push({
						path: '/dashboard'
					})
					.catch((error: Error) => error)
				return
			}
			this.$message.error('登录失败')
		}
	}
	doLogout() {
		logout()
	}
	goToLogin() {
		const config = getLoginConfig() || loginInit()
		window.location.href = `${config.login_url}?redirect_uri=${location.origin}/login`
		return
	}
}
